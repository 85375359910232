var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    _vm._g(
      _vm._b(
        {
          staticClass: "timeline-item",
          class: [
            "timeline-variant-" + _vm.variant,
            _vm.fillBorder ? "timeline-item-fill-border-" + _vm.variant : null,
          ],
        },
        "li",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      !_vm.icon
        ? _c("div", { staticClass: "timeline-item-point" })
        : _c(
            "div",
            {
              staticClass:
                "timeline-item-icon d-flex align-items-center justify-content-center rounded-circle",
              style: "border: 1px solid " + _vm.variant,
            },
            [
              _c("i", {
                class: "bi bi-" + _vm.icon,
                style: "color: " + _vm.variant,
              }),
            ]
          ),
      _vm._t("default", function () {
        return [
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c(
                "h6",
                {
                  staticClass: "mb-30",
                  style:
                    "color: " +
                    _vm.variant +
                    "; margin-bottom: 4px; font-size: 1.2rem; line-height: 1.9;",
                },
                [
                  _vm._v(" " + _vm._s(_vm.title) + " "),
                  _c("small", {
                    staticClass:
                      "timeline-item-time text-nowrap text-dark float-right",
                    domProps: { textContent: _vm._s(_vm.time) },
                  }),
                ]
              ),
            ]),
            _c("div", { staticClass: "col-md-12" }, [
              _c("p", {
                staticClass: "mb-0",
                staticStyle: {
                  "margin-top": "5px",
                  color: "#000",
                  opacity: "0.8",
                },
                domProps: { innerHTML: _vm._s(_vm.subtitle) },
              }),
            ]),
          ]),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }